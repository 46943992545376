const storageKey = "FAQ_DATA";

export const localStoragePorts = (app) => {
  app.ports.setLocalStorage.subscribe((data) => {
    if (data.length > 0) {
      localStorage.setItem(storageKey, data);
    }
  });

  app.ports.clearLocalStorage.subscribe((_) => {
    localStorage.removeItem(storageKey);
  });
};

export const getAuthInfo = () => {
  try {
    const data = localStorage.getItem(storageKey);

    if (data != null) {
      const parsedData = JSON.parse(data);
      if (
        parsedData.token != null &&
        typeof parsedData.token === "string" &&
        parsedData.role != null &&
        typeof parsedData.token === "string"
      ) {
        return { token: parsedData.token, role: parsedData.role };
      }
    }
  } catch (error) {
    console.log(error);
  }

  return null;
};
