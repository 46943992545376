import "./main.css";
import { getAuthInfo, localStoragePorts } from "./Port/localStorage.js";
import { editorPorts } from "./Port/editor.js";
import { Elm } from "./Main.elm";
import * as serviceWorker from "./serviceWorker";

const app = Elm.Main.init({
  node: document.getElementById("root"),
  flags: {
    authInfo: getAuthInfo(),
  },
});

localStoragePorts(app);
editorPorts(app);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
