const editorID = "editorMarkdownBot";

export const editorPorts = (app) => {
  app.ports.setMarkdown.subscribe((data) => {
    initEditor(app, data);
  });
};

const initEditor = (app, data) => {
  if (document.getElementById(editorID) != null) {
    const editor = new toastui.Editor({
      el: document.getElementById(editorID),
      width: "600px",
      height: "400px",
      initialEditType: "wysiwyg",
      initialValue: data,
      previewStyle: "vertical",
      hideModeSwitch: false,
      toolbarItems: [
        ["heading", "bold", "italic", "strike"],
        ["ul", "ol", "link"],
      ],
      events: {
        change: () => {
          app.ports.markDownReceiver.send(editor.getMarkdown());
        },
      },
    });
    return;
  }
  setTimeout(() => {
    initEditor(app, data);
  }, 10);
};
